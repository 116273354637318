// Cyphr.me Home page.
"use strict";

import * as App from '../app/app~fv=-eaZNRTI.min.js';
import QrScanner from '../pkg/qr-scanner.min.js'

// Page Modals
var scanQRModal;

App.AddOnload(SearchOnload);

/**
SearchOnload
@returns {void}
 */
function SearchOnload() {
	const searchBtn = document.querySelector('#searchBtn')
	const searchInput = document.querySelector("#searchFormInput")

	searchBtn.addEventListener('click', function(e) {
		if (IframeURL) {
			let v = document.querySelector("#searchFormInput").value
			console.log("IframeURL:", IframeURL, v)
			let d = v.match(/[^/]+$/)[0] // Grab digest by last slash.  TODO this needs to be more comprehensive.
			console.log(d)
			// let site = window.location.origin // DELETEME unneeded.  Iframe sees whatever site you give it. 
			// if (site != App.CyphrMeSite && site != "https://localhost:8081") { // Should redirect correctly on local dev or prod.  No other sites allowed.
			// 	site = App.CyphrMeSite
			// }
			window.location = window.location.origin + App.Page.IframeAC + "/" + d
			return
		}
		window.location = "/search/" + document.querySelector("#searchFormInput").value;
	});

	// Modals
	let modalOpts = {
		keyboard: true
	};
	scanQRModal = new bootstrap.Modal(document.getElementById('scanQRModal'), modalOpts);


	///////////////////////////
	// QR scanning modal.  

	document.querySelector(".scanQRBtn").addEventListener("click", (item) => {
		console.log("Start scan QR");
		scanQRModal.toggle();
		let videoElem = document.getElementById("scanQRVideo");

		const qrScanner = new QrScanner(
			videoElem,
			result => scanQRCallback(result), {
				highlightScanRegion: true,
			},
		);

		function scanQRCallback(result) {
			console.log('decoded qr code:', result);
			scanQRModal.toggle();
			console.log(result.data);
			searchInput.value = result.data;
			// searchInput.dispatchEvent(new Event('input')); // Actually trigger event on input.
			searchBtn.dispatchEvent(new Event('click'));
		}

		// Stop scanning when the modal is closed.  (The bootstrap modal object is
		// not the html element, so select it by ID. )
		document.getElementById('scanQRModal').addEventListener('hidden.bs.modal', event => {
			qrScanner.stop();
		});

		qrScanner.start();
	});

};